.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

h2 {
  font-size: 30px;
  margin-bottom: 15px !important;
}

.quiz-synopsis {
  font-size: 15px;
  margin-bottom: 30px !important;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  color: #282c34 !important;
  border-radius: 5px !important;
  padding: 8px 10px;
}

h3 {
  margin-bottom: 30px !important;
}

.tag-container span {
  padding: 5px 8px !important;
  font-size: 10px !important;
  margin-right: 3px;
  font-weight: bold;
  text-transform: uppercase !important;
}

.tag-container {
  margin-bottom: 25px !important;
  display: none !important;
}

.react-quiz-container .questionWrapper .btn {
  border-radius: 4px !important;
  border: 0px solid #e8e8e8 !important;
  font-size: 15px !important;
  font-weight: normal !important;
}

.react-quiz-container h2 {
  font-size: 20px !important;
}

.react-quiz-container .incorrect {
  background-color: #e74c3c !important;
}

.nextQuestionBtn {
  color: #282c34 !important;
  border-radius: 5px !important;
  padding: 8px 10px !important;
}

.react-quiz-container .questionWrapper .btn.correct {
  background-color: #27ae60 !important;
}

.react-quiz-container .result-answer-wrapper h3 {
  background-color: transparent !important;
  opacity: 1 !important;
  color: #ffffff !important;
  margin-bottom: 0px !important;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 30px !important;
  margin-top: 0px !important;
  border: 0px !important;
}
.quiz-result-filter {
  margin: 0px auto !important;
}

button:disabled {
  background-color: #3b3f46;
  color: #676c75;
}

.card-body h2 {
  font-size: 25px !important;
  font-weight: normal !important;
  padding: 0px 80px !important;
}

.card-body h2:nth-of-type(2n),
.card-body br {
  display: none !important;
}

.quiz-result-filter {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.react-quiz-container {
  max-width: 90% !important;
}
.btn-1 {
  padding: 5px 8px !important;
  font-size: 10px !important;
  margin-right: 3px;
  font-weight: bold;
  text-transform: uppercase !important;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 10px;
}

.btn-2 {
  padding: 5px 8px !important;
  font-size: 10px !important;
  margin-right: 3px;
  font-weight: bold;
  text-transform: uppercase !important;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 0px;
}

.react-quiz-container .questionBtnContainer {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  .App-header {
    display: block !important;
    padding-top: 50px;
  }
}
